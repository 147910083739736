import request from '@/utils/request'
let resquest = '/api/'

export function pageAuthLoginLog(data) {
  return request({
    url: `${resquest}authLoginLog/page`,
    method: 'post',
    data,
  })
}

export function pageBaseSystemLog(data) {
  return request({
    url: `${resquest}baseSystemLog/page`,
    method: 'post',
    data,
  })
}

export function pageTaskManage(data) {
  return request({
    url: `${resquest}taskManage/page`,
    method: 'post',
    data,
  })
}

export function pageDraftCopy(data) {
  return request({
    url: `${resquest}draftCopy/page/v2`,
    method: 'post',
    data,
  })
}

export function pageDraftCopyTwo(data) {
  return request({
    url: `${resquest}draftCopy/page`,
    method: 'post',
    data,
  })
}
export function pageDraftCopyThree(data) {
  return request({
    url: `${resquest}draftCopy/page2`,
    method: 'post',
    data,
  })
}

export function pageBasicFiles(data) {
  return request({
    url: `${resquest}basicFiles/page`,
    method: 'post',
    data,
  })
}

export function saveBasicFiles(data) {
  return request({
    url: `${resquest}basicFiles/create`,
    method: 'post',
    data,
  })
}

export function editBasicFiles(data) {
  return request({
    url: `${resquest}basicFiles/edit`,
    method: 'post',
    data,
  })
}

export function saveBasicFilesDetails(data) {
  return request({
    url: `${resquest}basicFiles/createDetails`,
    method: 'post',
    data,
  })
}

export function editBasicFilesDetails(data) {
  return request({
    url: `${resquest}basicFiles/editDetails`,
    method: 'post',
    data,
  })
}

export function detailsBasicFiles(data) {
  return request({
    url: `${resquest}basicFiles/details`,
    method: 'post',
    data,
  })
}

export function deleteDetails(data) {
  return request({
    url: `${resquest}basicFiles/deleteDetails`,
    method: 'post',
    data,
  })
}

export function changeStatusBasicFiles(data) {
  return request({
    url: `${resquest}basicFiles/changeStatus`,
    method: 'post',
    data,
  })
}

export function deleteBasicFiles(data) {
  return request({
    url: `${resquest}basicFiles/delete`,
    method: 'post',
    data,
  })
}

export function exportBasicFiles(data) {
  return request({
    url: `${resquest}basicFiles/export`,
    method: 'post',
    data,
  })
}

export function exportDraftCopy(data) {
  return request({
    url: `${resquest}draftCopy/export`,
    method: 'post',
    data,
  })
}

export function exportProfile(data) {
  return request({
    url: `${resquest}draftCopy/exportProfile`,
    method: 'post',
    data,
  })
}

export function listAllTaskName(params) {
  return request({
    url: `${resquest}taskManage/listAllTaskName`,
    method: 'get',
    params,
  })
}

export function importBasicFileForInsert(data) {
  return request({
    url: `${resquest}basicFiles/importForInsert`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: localStorage.getItem('token'),
    },
  })
}

export function importBasicFileForUpdate(data) {
  return request({
    url: `${resquest}basicFiles/importForUpdate`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: localStorage.getItem('token'),
    },
  })
}

export function pageCustomerMapping(data) {
  return request({
    url: `${resquest}customerMapping/page`,
    method: 'post',
    data,
  })
}

export function saveCustomerMapping(data) {
  return request({
    url: `${resquest}customerMapping/create`,
    method: 'post',
    data,
  })
}

export function editCustomerMapping(data) {
  return request({
    url: `${resquest}customerMapping/edit`,
    method: 'post',
    data,
  })
}

export function saveCustomerMappingDetails(data) {
  return request({
    url: `${resquest}customerMapping/createDetails`,
    method: 'post',
    data,
  })
}

export function editCustomerMappingDetails(data) {
  return request({
    url: `${resquest}customerMapping/editDetails`,
    method: 'post',
    data,
  })
}

export function detailsCustomerMapping(data) {
  return request({
    url: `${resquest}customerMapping/details`,
    method: 'post',
    data,
  })
}

export function deleteCustomerMappingDetails(data) {
  return request({
    url: `${resquest}customerMapping/deleteDetails`,
    method: 'post',
    data,
  })
}

export function changeStatusCustomerMapping(data) {
  return request({
    url: `${resquest}customerMapping/changeStatus`,
    method: 'post',
    data,
  })
}

export function deleteCustomerMapping(data) {
  return request({
    url: `${resquest}customerMapping/delete`,
    method: 'post',
    data,
  })
}

export function exportCustomerMapping(data) {
  return request({
    url: `${resquest}customerMapping/export`,
    method: 'post',
    data,
  })
}

export function importBCustomerMappingForInsert(data) {
  return request({
    url: `${resquest}customerMapping/importForInsert`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: localStorage.getItem('token'),
    },
  })
}

export function importCustomerMappingUpdate(data) {
  return request({
    url: `${resquest}customerMapping/importForUpdate`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: localStorage.getItem('token'),
    },
  })
}

export function pageClearanceList(data) {
  return request({
    url: `${resquest}clearanceList/page2`,
    method: 'post',
    data,
  })
}
export function pageClearanceListTwo(data) {
  return request({
    url: `${resquest}clearanceList/page2`,
    method: 'post',
    data,
  })
}

export function tmsDataSync(data) {
  return request({
    url: `${resquest}clearanceList/tmsDataSync`,
    method: 'post',
    data,
  })
}

export function deleteClearanceList(data) {
  return request({
    url: `${resquest}clearanceList/delete`,
    method: 'post',
    data,
  })
}

export function draftCopyExport(data) {
  return request({
    url: `${resquest}clearanceList/draftCopyExport`,
    method: 'post',
    data,
  })
}

export function clearanceListExportProfile(data) {
  return request({
    url: `${resquest}clearanceList/exportProfile`,
    method: 'post',
    data,
  })
}

export function clearanceListMathHsData(data) {
  return request({
    url: `${resquest}clearanceList/mathHsData`,
    method: 'post',
    data,
  })
}

export function createFileClearanceList(data) {
  return request({
    url: `${resquest}clearanceList/createFile`,
    method: 'post',
    data,
  })
}

export function confirmClearanceList(data) {
  return request({
    url: `${resquest}clearanceList/confirm`,
    method: 'post',
    data,
  })
}

export function cancelClearanceList(data) {
  return request({
    url: `${resquest}clearanceList/cancel`,
    method: 'post',
    data,
  })
}

export function updateClearanceList(data) {
  return request({
    url: `${resquest}clearanceList/update`,
    method: 'post',
    data,
  })
}

export function updateDraftClearanceList(data) {
  return request({
    url: `${resquest}clearanceList/updateDraft`,
    method: 'post',
    data,
  })
}

export function importClearanceList(data) {
  return request({
    url: `${resquest}clearanceList/import`,
    method: 'post',
    data,
  })
}

export function getOneCmsCount() {
  return request({
    url: `${resquest}cmsHsInternetCount/getOneCmsCount`,
    method: 'post',
  })
}

export function exportClearanceList(data) {
  return request({
    url: `${resquest}clearanceList/export`,
    method: 'post',
    data,
  })
}

//成交状态
export function getValidList(data) {
  return request({
    url: `${resquest}base/dist/getValidList`,
    method: 'post',
    data,
  })
}

//港口
export function portGetValidList(data) {
  return request({
    url: `${resquest}base/port/getValidList`,
    method: 'post',
    data,
  })
}

//省市区
export function areasGetValidList(data) {
  return request({
    url: `${resquest}base/areas/getValidList`,
    method: 'post',
    data,
  })
}
//业务员
export function salesmanGetValidList(data) {
  return request({
    url: `${resquest}base/salesman/getValidList`,
    method: 'post',
    data,
  })
}

//产品专员
export function productCommissionerGetValidList(data) {
  return request({
    url: `${resquest}base/productCommissioner/getValidList`,
    method: 'post',
    data,
  })
}

//散货查询接口
export function bulkCargoList(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/list`,
    method: 'post',
    data,
  })
}

//创建散货询价接口
export function bulkCargoSave(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/save`,
    method: 'post',
    data,
  })
}

//散货询价编辑回显接口
export function bulkCargoGetById(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/getById`,
    method: 'get',
    params,
  })
}

//散货询价复制回显数据接口
export function bulkCargoCopy(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/copy`,
    method: 'get',
    params,
  })
}

//散货询价修改接口
export function bulkCargoEdit(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/edit`,
    method: 'post',
    data,
  })
}

//散货询价删除接口
export function bulkCargoDelete(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/delete`,
    method: 'get',
    params,
  })
}

//散货修改报价状态
export function bulkCargoUpdateDetailStatus(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/updateDetailStatus`,
    method: 'post',
    data,
  })
}

//拖车查询接口
export function bulkCargoCarList(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/list`,
    method: 'post',
    data,
  })
}

//创建拖车询价接口
export function bulkCargoCarSave(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/save`,
    method: 'post',
    data,
  })
}

//拖车询价编辑回显接口
export function bulkCargoCarGetById(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/getById`,
    method: 'get',
    params,
  })
}

//拖车询价复制回显数据接口
export function bulkCargoCarCopy(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/copy`,
    method: 'get',
    params,
  })
}

//拖车询价修改接口
export function bulkCargoCarEdit(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/edit`,
    method: 'post',
    data,
  })
}

//拖车询价删除接口
export function bulkCargoCarDelete(params) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/delete`,
    method: 'get',
    params,
  })
}
//拖车修改报价状态
export function bulkCargoCarUpdateDetailStatus(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/updateDetailStatus`,
    method: 'post',
    data,
  })
}

//地址检索接口
export function areasPlaceSuggestion(params) {
  return request({
    url: `${resquest}base/areas/placeSuggestion`,
    method: 'get',
    params,
  })
}
//散货修改报价状态前查询该询价是否已有其他&lt;已成交&gt;状态的报价信息
export function bulkCargoCheckDetailStatus(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargo/checkDetailStatus`,
    method: 'post',
    data,
  })
}

//拖车修改报价状态前查询该询价是否已有其他&lt;已成交&gt;状态的报价信息
export function bulkCargoCarCheckDetailStatus(data) {
  return request({
    url: `${resquest}inquiryInfo/bulkCargoCar/checkDetailStatus`,
    method: 'post',
    data,
  })
}

//草稿
export function customerMappingDraft(data) {
  return request({
    url: `${resquest}customerMapping/draft/page`,
    method: 'post',
    data,
  })
}
export function draftEdit(data) {
  return request({
    url: `${resquest}customerMapping/draft/edit`,
    method: 'post',
    data,
  })
}
export function draftDelete(data) {
  return request({
    url: `${resquest}customerMapping/draft/delete`,
    method: 'post',
    data,
  })
}
export function draftImportForUpdate(data) {
  return request({
    url: `${resquest}customerMapping/draft/importForUpdate`,
    method: 'post',
    data,
  })
}
export function draftExport(data) {
  return request({
    url: `${resquest}customerMapping/draft/export`,
    method: 'post',
    data,
  })
}

export function searchListApi(id) {
  return request({
    url: `${resquest}customerMappingRecommend/list/${id}`,
    method: 'get',
  })
}

//客户使用频次列表查询接口
export function customerMappingFrequencyListApi(params) {
  return request({
    url: `${resquest}customerMappingFrequency/list/${params.id}`,
    method: 'get',
  })
}

export function hsCodeDictListApi(data) {
  return request({
    url: `${resquest}hsCodeDict/page`,
    method: 'post',
    data,
  })
}

export function hsCodeDictSaveApi(data) {
  return request({
    url: `${resquest}hsCodeDict/save`,
    method: 'post',
    data,
  })
}

export function hsCodeDictDeleteApi(data) {
  return request({
    url: `${resquest}hsCodeDict/delete`,
    method: 'post',
    data,
  })
}

export function inspectioncodeDictApi(data) {
  return request({
    url: `${resquest}inspectioncodeDict/lists`,
    method: 'post',
    data,
  })
}

export function regulatoryConditionCodeDictApi(data) {
  return request({
    url: `${resquest}regulatoryConditionCodeDict/lists`,
    method: 'post',
    data,
  })
}

export function hsCodeDictImportDatasApi(data) {
  return request({
    url: `${resquest}/hsCodeDict/importDatas`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: localStorage.getItem('token'),
    },
  })
}

export function hsCodeDictExportApi(data) {
  return request({
    url: `${resquest}/hsCodeDict/export`,
    method: 'post',
    data,
  })
}

export function clearanceListQueryHscApi(data) {
  return request({
    url: `${resquest}/clearanceList/queryHsc`,
    method: 'post',
    data,
  })
}

export function saveSelectHscApi(data) {
  return request({
    url: `${resquest}/customerMappingRecommend/verify/recommend`,
    method: 'post',
    data,
  })
}

export function listSetting(data) {
  return request({
    url: `${resquest}/list-setting`,
    method: 'post',
    data,
  })
}

export function listSettingQuery(params) {
  return request({
    url: `${resquest}/list-setting`,
    method: 'get',
    params: {
      tableName: params,
    },
  })
}

export function updateHsCodeApi(data) {
  return request({
    url: `${resquest}/clearanceList/updateHsCode/batch`,
    method: 'post',
    data,
  })
}

export function exportClearanceData(data) {
  return request({
    url: `${resquest}draftCopy/export/clearanceData`,
    method: 'post',
    data,
  })
}

export function addHighRiskCmsHighRiskData(data) {
  return request({
    url: `${resquest}cmsHighRiskData/addHighRisk`,
    method: 'post',
    data,
  })
}

export function addSpiderTaskCmsClearanceSpider(data) {
  return request({
    url: `${resquest}cmsClearanceSpider/addSpiderTask`,
    method: 'post',
    data,
  })
}
